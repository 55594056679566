import 'prowhy-js-helpers';

import 'menus.js.erb';
import 'tags.js.erb';
import 'cause_trees.js.erb';
import 'ishikawa_prowhy.js.erb';
import 'tree_contents.js.erb';
import 'perimeters.js.erb';

import 'custom_fields.js';
// import 'charts_home_page.js'
// import 'ishikawa.js.erb'

// // import 'fabric_tests.js';
// // import "tasks.js.erb";

// Import scss
// import '../stylesheets/index'

import 'pagy.js.erb';

// import 'test_pageunload.js';
